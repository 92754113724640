report-view {
  display: block;
}
@media print {
  report-view {
    padding: 0 var(--box-padding);
  }
}
report-view .report-description {
  display: none;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1.6rem;
  grid-row-gap: 0.8rem;
}
@media print {
  report-view .report-description {
    display: grid;
  }
}
report-view .report-description .report-description-item {
  display: flex;
  white-space: normal;
  word-break: break-all;
  overflow: hidden;
}
report-view .report-description .report-description-item .label {
  width: 25%;
}
report-view .report-description {
  width: initial;
}
report-view .report-description tr td {
  width: initial;
  white-space: pre-wrap;
}
report-view .report-description tr:first-child td {
  border-top: none;
}
report-view .table--report td {
  border: 1px solid var(--light-line-color);
}
report-view .table--report td:after {
  display: none;
}
report-view .table th {
  border-top: none;
  text-align: left;
}
report-view .table tr.report-summary td {
  text-align: left;
  border: none;
}
report-view .table th.align-right,
report-view .table tr.report-summary td.align-right {
  text-align: right;
}
report-view .table td.no-wrap {
  white-space: nowrap;
}
report-view .table .report-separator td {
  border-color: transparent;
}
report-view .cursor-default {
  cursor: default;
}
report-view pre {
  font-family: var(--font-family);
  background-color: transparent;
  display: block;
  padding: 0;
  margin: 0;
  word-break: normal;
  word-wrap: break-word;
  white-space: pre-wrap;
  border: none;
  text-align: left;
}
report-view pre.align-right {
  text-align: right;
}
report-view .key-value-container {
  align-items: center;
  display: flex;
  padding: 8px 0;
}
report-view .key-value-label {
  cursor: default;
  font-weight: var(--font-weight-semibold);
  margin: 0;
}
report-view .key-value-label:after {
  content: ':';
  display: inline-block;
}
report-view .key-value-value {
  margin: 0;
  padding-left: 10px;
}
report-view .report-table-wrapper {
  border: 1px solid var(--light-line-color);
  border-radius: var(--default-border-radius);
  width: 100%;
  max-width: 100%;
  margin-top: 2rem;
  margin-bottom: 3rem;
  overflow: auto;
}
@media print {
  report-view .report-table-wrapper {
    border: 0;
    width: auto;
    max-width: none;
    overflow: visible;
    max-height: none;
  }
  report-view .report-table-wrapper .table {
    max-width: 100%;
    width: 100%;
  }
  report-view .report-table-wrapper .table tbody tr {
    page-break-inside: avoid;
  }
}
report-view .report-table-wrapper .table {
  margin-bottom: 0;
  margin-left: -1px;
  margin-right: -1px;
  width: 100%;
}
